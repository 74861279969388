
import { Vue, Component, Watch } from 'vue-property-decorator'
import { mapState } from 'vuex'
import CategoryBreadcrumb from '@/components/CategoryBreadcrumb.vue'
import UserBadges from '@/components/UserBadges.vue'
import UserGradeGroup from '@/components/UserGradeGroup.vue'
import Profile from '@/components/Profile.vue'

@Component({
  computed: { ...mapState(['isLoading']) },
  components: {
    Profile,
    CategoryBreadcrumb,
    UserBadges,
    UserGradeGroup
  }
})
export default class Interviews extends Vue {
  profiles: any[] = []
  counts: any = {
    work_interviews: 0,
    school_interviews: 0,
    dream_interview: 0,
    lesson_interview: 0,
    date_interviews: 0,
    charm_interviews: 0,
    wish_interviews: 0,
    favorite_interviews: 0,
    nickname: 0,
  }

  isAwaitConfirmReviews = false

  mounted () {
    this.onCategoryChanged()
  }

  get categories () {
    return [
      { key: 'work_interviews', text: 'Work', route: { name: 'review-interviews', params: { category: 'work-interviews' } } },
      { key: 'school_interviews', text: 'School', route: { name: 'review-interviews', params: { category: 'school-interviews' } } },
      { key: 'faith', text: 'Faith', route: { name: 'review-interviews', params: { category: 'faith' } } },
      { key: 'church_interviews', text: 'Church', route: { name: 'review-interviews', params: { category: 'church-interviews' } } },
      { key: 'place_interviews', text: 'Place', route: { name: 'review-interviews', params: { category: 'place-interviews' } } },
      { key: 'memory_interviews', text: 'Memory', route: { name: 'review-interviews', params: { category: 'memory-interviews' } } },
      { key: 'charm_interviews', text: 'Charm', route: { name: 'review-interviews', params: { category: 'charm-interviews' } } },
      { key: 'food_interviews', text: 'Food', route: { name: 'review-interviews', params: { category: 'food-interviews' } } },
      { key: 'prayer_interviews', text: 'Prayer', route: { name: 'review-interviews', params: { category: 'prayer-interviews' } } },
      { key: 'date_interviews', text: 'Date', route: { name: 'review-interviews', params: { category: 'date-interviews' } } },
      { key: 'vision_interview', text: 'Vision', route: { name: 'review-interviews', params: { category: 'vision-interview' } } },
      { key: 'episode_interviews', text: 'Episode', route: { name: 'review-interviews', params: { category: 'episode-interviews' } } },
      { key: 'hope_interviews', text: 'Hope', route: { name: 'review-interviews', params: { category: 'hope-interviews' } } },
      { key: 'nickname', text: '닉네임', route: { name: 'review-interviews', params: { category: 'nickname' } } },
    ]
  }

  get category () { return (this.$route.params.category || this.categories[0].key).replace(/-/g, '_') }

  @Watch('category')
  onCategoryChanged () {
    this.profiles = []

    if (this.category) {
      this.$store.dispatch('getWaitReviewProfiles', { params: { category: this.category } })
        .then(({data}) => {
          this.profiles = data.profiles
          this.counts = data.counts
        })

      this.isAwaitConfirmReviews = false
    }
  }

  reviewProfile (profile: any, status: string) {
    this.$set(profile, 'status', status)
  }

  async approveWaitReview (profile: any) {
    this.$set(profile, 'status', 'wait_approve')

    if (await this.$bvModal.msgBoxConfirm('승인 처리하시겠습니까? (* 반영 이후엔 되돌릴 수 없습니다.)', { okVariant: 'success', autoFocusButton: 'ok' })) {
      this.requestProfileReview(profile)
    }
  }

  async confirmProfileReview (profile: any) {
    if (await this.$bvModal.msgBoxConfirm('심사를 반영하시겠습니까? (* 반영 이후엔 되돌릴 수 없습니다.)', { okVariant: 'dark', autoFocusButton: 'ok' })) {
      this.requestProfileReview(profile)
    }
  }

  requestProfileReview (profile: any) {
    if ( ! ['wait_reject', 'wait_approve'].includes(profile.status)) return

    this.isAwaitConfirmReviews = true

    this.$store.dispatch('reviewProfile', {
        id: profile.id,
        params: {
          status: profile.status,
          rejection_comment: profile.rejection_comment,
          review_token_confirmation: profile.review_token,
        }
      })
      .finally(() => {
        this.$bvToast.toast(`[User#${profile.user_id}] 심사가 반영되었습니다.`, {
          title: '알림',
          variant: 'info'
        })
        this.onCategoryChanged()
      })
  }

  async confirmAllProfiles () {
    const h = this.$createElement
    const message = h('div', {
      domProps: {
        innerHTML: `
          페이지(${this.profiles.length}개) 전체 심사 확정 처리하시겠습니까?<br/>
          (* 심사 상태 미지정시 승인으로 간주하며, 반영 이후엔 되돌릴 수 없습니다.)
        `
      }
    })

    if (await this.$bvModal.msgBoxConfirm([message], { okVariant: 'dark', autoFocusButton: 'ok' })) {
      let reviewCount = 0

      const requests: Promise<void>[] = []

      this.profiles.forEach(async (profile) => {
        if (profile.status == 'wait_review') {
          // 심사 대기중인 항목 승인 상태로 변경
          this.reviewProfile(profile, 'wait_approve')
        }

        if (['wait_reject', 'wait_approve'].includes(profile.status)) {
          reviewCount += 1

          requests.push(
            this.$store.dispatch('reviewProfile', {
              id: profile.id,
              params: {
                status: profile.status,
                rejection_comment: profile.rejection_comment,
                review_token_confirmation: profile.review_token
              }
            }).catch(({ response }) => {
              reviewCount -= 1

              this.$bvToast.toast(response.data.message || response, {
                title: '심사 오류',
                variant: 'danger'
              })
            })
          )
        }
      })

      Promise.all(requests).finally(() => {
        this.$bvToast.toast(`${reviewCount}개의 항목에 대한 심사가 처리되었습니다.`, {
          title: '알림',
          variant: 'info'
        })
        this.onCategoryChanged()
      })
    }
  }
}
