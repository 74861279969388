
import { Vue, Component, Watch } from 'vue-property-decorator'
import { mapState } from 'vuex'

@Component({
  computed: { ...mapState(['isLoading']) }
})
export default class Notices extends Vue {
  notices: any = []

  get variant () {
    return (this.notices.length == 0) ? 'secondary' : (this.notices.length >= 100) ? 'warning' : 'primary'
  }

  getNotices ($state: any) {
    let params: any = {}

    if (this.notices.length > 0) {
      params['offset_id'] = this.notices[this.notices.length - 1].id
    }

    this.$store.dispatch('getNotices', { params: params })
      .then(({ data }) => {
        if (data.notices.length > 0) {
          this.notices.push(...data.notices)

          $state.loaded()
        } else {
          $state.complete()
        }
      })
  }
}
