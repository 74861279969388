
import { Vue, Component, Watch } from 'vue-property-decorator'
import { mapState } from 'vuex'
import { BDropdown } from 'bootstrap-vue'

@Component({
  computed: { ...mapState(['isLoading']) },
  props: ['email', 'userId']
})
export default class EmailContainer extends Vue {
  $refs!: {
    dropdown: BDropdown
  }

  email!: string
  userId!: number

  isViewMode: boolean = true
  editValue?: string

  toggleViewMode () {
    this.isViewMode = ! this.isViewMode
    this.$refs.dropdown.hide(true)
  }

  @Watch('isViewMode')
  onViewModeChanged () {
    if (this.isViewMode) return

    this.editValue = this.email
  }

  async updateEmail () {
    if (this.editValue && this.editValue != this.email) {
      if (await this.$bvModal.msgBoxConfirm('저장하시겠습니까? (* 이메일 변경시 로그인 세션이 풀립니다.)', { okVariant: 'dark', autoFocusButton: 'ok' })) {
        this.$store.dispatch('updateUser', { id: this.userId, params: { email: this.editValue } })
          .then(() => {
            this.$emit('reload')
            this.toggleViewMode()
          })
          .catch(({ response }) => {
            this.$bvToast.toast(response.data.message || response, {
              title: '이메일 변경 오류',
              variant: 'danger'
            })
          })
      }
    } else {
      this.toggleViewMode()
    }
  }
}
