
import { Vue, Component, Watch } from 'vue-property-decorator'
import { BDropdown, BModal } from 'bootstrap-vue'
import ProfileValue from '@/components/ProfileValue.vue'

@Component({
  components: { ProfileValue },
  props: ['profile', 'isLogComponent', 'plainHeader', 'preventEdit']
})
export default class OccupationGroupsItem extends Vue {
  $refs!: {
    dropdown: BDropdown
  }

  profile!: any
  isLogComponent!: boolean
  preventEdit!: boolean

  isViewMode: boolean = true
  isValuesExpanded: boolean = true
  isFilterVisible: boolean = false

  editValueDiv0: string | null = null
  editValueDiv1: string | null = null

  itemsData: any = null

  get isReviewable (): boolean { return this.profile.is_reviewable && ! this.isLogComponent }
  get isCollapsable (): boolean { return this.$store.getters.isCollapsableProfile(this.profile.category) }
  get isArrayValue (): boolean { return Array.isArray(this.profile.value) }

  get itemsDiv0 (): Array<string> {
    return this.itemsData == null ? [] : Object.keys(this.itemsData)
  }

  get itemsDiv1 (): Array<string> {
    return this.itemsData == null ? [] : this.editValueDiv0 == null ? [] : this.itemsData[this.editValueDiv0]
  }

  mounted () {
    this.$store.dispatch('getDatasets')
        .then(({ data }) => {
          this.itemsData = data.datasets.find((node: any) => node.key == this.profile.category).value
        })
  }

  toggleFilter () {
    if (this.isLogComponent) this.$emit('filter-by-category', this.profile.category)
  }

  toggleViewMode () {
    this.isViewMode = ! this.isViewMode
    this.$refs.dropdown.hide(true)
  }

  @Watch('isViewMode')
  onViewModeChanged () {
    if (this.isViewMode) return

    if (this.preventEdit) {
      this.isViewMode = false
      if ( ! this.isLogComponent) {
        this.$bvToast.toast("수정이 불가능한 항목입니다.", {
          title: '알림',
          variant: 'danger'
        })
      }
    } else {
      this.editValueDiv0 = this.profile.value[0]
      this.editValueDiv1 = this.profile.value[1]
    }
  }

  @Watch('editValueDiv0')
  changeEditValueDiv0 (newValue: any, oldValue: any) {
    if (oldValue && newValue != oldValue) this.editValueDiv1 = null
  }

  hoverHandler (isHovered: any) {
    this.isFilterVisible = isHovered
  }

  async updateProfile () {
    if (this.editValueDiv0 && this.editValueDiv1) {
      let params: any = {}

      params[this.profile.category] = [
        this.editValueDiv0,
        this.editValueDiv1,
      ]

      if (await this.$bvModal.msgBoxConfirm('저장하시겠습니까? (* 관리자 수정 시 바로 승인처리 됩니다.)', { okVariant: 'dark', autoFocusButton: 'ok' })) {
        this.$store.dispatch('updateProfile', { userId: this.profile.user_id, params: params })
          .then(() => {
            this.$emit('reload')
            this.toggleViewMode()
          })
          .catch(({ response }) => {
            this.$bvToast.toast(response.data.message || response, {
              title: '프로필 수정 오류',
              variant: 'danger'
            })
          })
      }
    } else {
      this.toggleViewMode()
    }
  }
}
