
import { Vue, Component, Watch } from 'vue-property-decorator'

@Component({ props: ['category', 'userId', 'userGrade'] })
export default class UserGradeGroup extends Vue {
  category!: any
  userId!: number
  userGrade!: string

  value: string = this.userGrade || ''

  get options () {
    switch (this.category) {
      case 'faith_grade': return ['S', 'A', 'B', 'C']
      case 'charming_grade': return ['A', 'B', 'C', 'D']
      default: return ['S', 'A', 'B', 'C', 'D', 'E', 'F']
    }
  }

  @Watch('value')
  updateGrade () {
    let params: {[name: string]: string } = {}

    params[this.category] = this.value

    this.$store.dispatch('updateProfile', { userId: this.userId, params: params })
  }
}
