
import { Vue, Component } from 'vue-property-decorator'
import { mapGetters } from 'vuex'

@Component({
  computed: {
    ...mapGetters({
      currentUser: "currentUser",
      watermarkText: "watermarkText"
    })
  }
})
export default class Watermark extends Vue {
  public currentUser!: any
  public watermarkText!: any

  get watermarkImage () {
    return `url('data:image/svg+xml;utf8,<svg height="100" width="200" xmlns="http://www.w3.org/2000/svg"><text x="1" y="100%" font-size="12" transform="rotate(-30)">${ this.watermarkText }</text></svg>')`
  }
}
