
import { Vue, Component } from 'vue-property-decorator'
import { mapState } from 'vuex'

@Component({ computed: { ...mapState({ isLoading: "isLoading" }) } })
export default class Login extends Vue {
  isLoading!: boolean

  email: string = ''
  password: string = ''

  get method () { return this.$route.query.method }

  onSubmit () {
    this.$store.dispatch('signIn', { params: { email: this.email, password: this.password } })
      .then(({ data }) => {
        this.$store.dispatch('signInWithToken', { token: data.token })
          .then(() => {
            this.$store.dispatch('getCurrentUser')
              .then(() => this.$router.replace({ path: '/' }))
              .catch((error) => {
                window.console.error(error)
                this.$bvModal.show('login-failed-token-server')
              })
          })
          .catch((error) => {
            window.console.error(error)
            this.$bvModal.show('login-failed-token-firebase')
          })
      })
      .catch(() => this.$bvModal.show('login-failed'))
  }

  signInWithGoogle () {
    this.$store.dispatch('signInWithGoogle')
      .then(async () => {
        await this.$store.dispatch('signedInNotify')
          .catch((error) => {
            // ignore error
            window.console.error(error)
          })

        this.$store.dispatch('getCurrentUser')
          .then(() => this.$router.replace({ path: '/' }))
          .catch((error) => {
            window.console.error(error)
            this.$bvModal.show('login-failed-token-server')
          })
      })
      .catch((error) => {
        window.console.error(error)
        this.$bvModal.show('login-failed-token-firebase')
      })
  }
}
