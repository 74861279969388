
import { Vue, Component, Watch } from 'vue-property-decorator'
import CategoryBreadcrumb from '@/components/CategoryBreadcrumb.vue'
import PhotoFrame from '@/components/PhotoFrame.vue'
import UserRedirectionButton from '@/components/UserRedirectionButton.vue'
import UserBadges from '@/components/UserBadges.vue'

@Component({
  props: ['category'],
  components: {
    CategoryBreadcrumb,
    PhotoFrame,
    UserRedirectionButton,
    UserBadges
  }
})
export default class Photos extends Vue {
  category!: string

  users: any = []
  counts: any = {
    serendipity_users: 0,
    odd_users: 0,
    even_users: 0,
    attention_marked_users: 0
  }

  focus: number = 0
  isAwaitConfirmReviews = false

  created () {
    window.addEventListener('keypress', this.onKeyPressed)
  }

  destroyed () {
    window.removeEventListener('keypress', this.onKeyPressed)
  }

  mounted () {
    this.onPartitionChanged()
  }

  onKeyPressed (event: KeyboardEvent) {
    const hotKeys = [49, 50, 51, 52, 53] // 1, 2, 3, 4, 5

    if (hotKeys.includes(event.keyCode)) {
      this.focus = hotKeys.indexOf(event.keyCode)
    }
  }

  changeFocus (moveTo: number) { this.focus = moveTo }

  get partitions () {
    return [
      { key: 'serendipity_users', text: '세렌디피티', route: { name: `review-${this.category}`, params: { partition: 'serendipity-users' } } },
      { key: 'odd_users', text: '홀수', route: { name: `review-${this.category}`, params: { partition: 'odd-users' } } },
      { key: 'even_users', text: '짝수', route: { name: `review-${this.category}`, params: { partition: 'even-users' } } },
      { key: 'attention_marked_users', text: '사진주시', route: { name: `review-${this.category}`, params: { partition: 'attention-marked-users' } } },
    ]
  }

  get partition () { return (this.$route.params.partition || this.partitions[0].key).replace(/-/g, '_') }

  @Watch('category')
  @Watch('partition')
  onPartitionChanged () {
    this.users = []

    if (this.category) {
      this.$store.dispatch('getWaitReviewPhotos', { params: { category: this.category, partition: this.partition } })
        .then(({data}) => {
          this.users = data.users
          this.counts = data.counts[this.category]
        })

      this.isAwaitConfirmReviews = false
    }
  }

  /**
   * @method getWaitReview
   * @param {Photo[]} photos 사진 목록
   * @returns {Photo} 
   * @description 전달받은 사진 목록에서 심사 대기중인 사진 하나 가져오기
   */
  getWaitReview (photos: any[]) {
    return photos.find((photo) => { return photo.status == 'wait_review' })
  }

  /**
   * @method hasWaitReview
   * @param {Photo[]} photos 사진 목록
   * @returns {boolean}
   * @description 전달받은 사진 목록에서 심사 대기중인 사진이 있는지 여부 확인
   */
  hasWaitReview (photos: any[]) {
    return this.getWaitReview(photos) != undefined
  }

  /**
   * @method approveWaitReviews
   * @param {Photo[]} photos 사진 목록
   * @description 심사 대기중인 사진들을 재귀로 돌면서 승인 대기 처리
   */
  approveWaitReviews (photos: any[]) {
    const photo = this.getWaitReview(photos)

    if (photo) {
      this.$set(photo, 'status', 'wait_approve')
      this.approveWaitReviews(photos)
    }
  }

  reviewPhoto (userId: number, photo: any, status: string) {
    this.$set(photo, 'status', status)
  }

  async confirmReviews (user: any) {
    if (await this.$bvModal.msgBoxConfirm('심사를 반영하시겠습니까? (* 반영 이후엔 되돌릴 수 없습니다.)', { okVariant: 'dark', autoFocusButton: 'ok' })) {
      this.isAwaitConfirmReviews = true

      for (const photo of user[this.category]) {
        if (['wait_reject', 'wait_approve'].includes(photo.status)) {
          await this.$store.dispatch('reviewPhoto', {
            id: photo.id,
            params: {
              status: photo.status,
              rejection_comment: photo.rejection_comment,
              review_token_confirmation: photo.review_token
            }
          })
        }
      }

      await this.$store.dispatch('reorderPhotos', { userId: user.id, params: { category: this.category } })

      this.$bvToast.toast(`[User#${user.id}] 심사가 반영되었습니다.`, {
        title: '알림',
        variant: 'info'
      })
      this.reloadUser(user)
          .then(() => this.isAwaitConfirmReviews = false)
    }
  }

  updateSelfiesAttentionMark (user: any, value: any) {
    this.$store.dispatch('updateUser', { id: user.id, params: { selfies_attention_marked_at: value } })
      .catch(({ response }) => {
        this.$bvToast.toast(response.data.message || response, {
          title: '정보 갱신 오류',
          variant: 'danger'
        })
      })
      .finally(() => this.reloadUser(user))
  }

  async reloadUser (user: any) {
    return this.$store.dispatch('getUser', { id: user.id })
               .then(({ data }) => {
                 const index = this.users.findIndex((item: any) => item.id == user.id)
                 const reloadedUser = data.user

                 if (this.hasWaitReview(reloadedUser[this.category])) {
                   reloadedUser.photo_updated_at = user.photo_updated_at

                   this.users.splice(index, 1, reloadedUser)
                 } else {
                   this.users.splice(index, 1)
                 }
               })
  }
}
