import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '@/views/Login.vue'
import ReviewUsers from '@/views/review/Users.vue'
import ReviewInterviews from '@/views/review/Interviews.vue'
import ReviewChurches from '@/views/review/Churches.vue'
import ReviewPhotos from '@/views/review/Photos.vue'
import ReviewCertificates from '@/views/review/Certificates.vue'
import SearchUsers from '@/views/search/Users.vue'
import SearchChurches from '@/views/search/Churches.vue'
import User from '@/views/User.vue'
import Couple from '@/views/Couple.vue'
import CoupleMessages from '@/views/CoupleMessages.vue'
import Notices from '@/views/notices/Index.vue'
import NoticeForm from '@/views/notices/Form.vue'
import Consults from '@/views/consults/Index.vue'
import BlockedUsers from '@/views/BlockedUsers.vue'
import SelfiesAttentionMarkedUsers from '@/views/SelfiesAttentionMarkedUsers.vue'
import Linkpay from '@/views/Linkpay.vue'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/search/users',
      name: 'search-users',
      component: SearchUsers,
    },
    {
      path: '/search/churches',
      name: 'search-churches',
      component: SearchChurches,
    },
    {
      path: '/users/:userId',
      name: 'user',
      component: User,
    },
    {
      path: '/couples/:coupleId',
      name: 'couple',
      component: Couple,
    },
    {
      path: '/review/users/:category?',
      name: 'review-users',
      component: ReviewUsers,
    },
    {
      path: '/review/interviews/:category?',
      name: 'review-interviews',
      component: ReviewInterviews,
    },
    {
      path: '/review/churches/:category?',
      name: 'review-churches',
      component: ReviewChurches,
    },
    {
      path: '/review/selfies/:partition?',
      name: 'review-selfies',
      component: ReviewPhotos,
      props: { category: 'selfies' }
    },
    {
      path: '/review/lifelogs/:partition?',
      name: 'review-lifelogs',
      component: ReviewPhotos,
      props: { category: 'lifelogs' }
    },
    {
      path: '/review/certificates/:category?',
      name: 'review-certificates',
      component: ReviewCertificates,
    },
    {
      path: '/messages',
      name: 'messages',
      component: CoupleMessages,
    },
    {
      path: '/notices/:noticeId',
      name: 'notice-form',
      component: NoticeForm,
    },
    {
      path: '/notices',
      name: 'notices',
      component: Notices,
    },
    {
      path: '/consults',
      name: 'consults',
      component: Consults,
    },
    {
      path: '/blocked-users',
      name: 'blocked-users',
      component: BlockedUsers,
    },
    {
      path: '/selfies-attention-marked-users',
      name: 'selfies-attention-marked-users',
      component: SelfiesAttentionMarkedUsers
    },
    {
      path: '/linkpay',
      name: 'linkpay',
      component: Linkpay
    },
    {
      path: '/login',
      component: Login,
      meta: { loginPage: true }
    },
    { path: '/', redirect: { name: 'review-users' } },
    { path: '', redirect: { name: 'review-users' } },
  ],
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  },
})

export default router