
import { Vue, Component } from 'vue-property-decorator'

@Component({ props: ['devices'] })
export default class Devices extends Vue {
  devices!: any[]

  device: any = null

  mounted () {
    if (this.devices.length > 0) this.device = this.devices[0]
  }
}
