
import { Vue, Component, Watch } from 'vue-property-decorator'
import { mapState } from 'vuex'
import InfiniteLoading from 'vue-infinite-loading'
import UserCard from '@/components/UserCard.vue'

@Component({
  components: { UserCard },
  computed: { ...mapState(['isLoading']) }
})
export default class Users extends Vue {
  $refs!: {
    infiniteLoading: InfiniteLoading
  }

  users: any = []
  query: string = this.q

  @Watch('$route', { immediate: true, deep: true })
  onUrlChanged () {
    this.query = this.q
    this.users = []

    if (this.$refs.infiniteLoading) {
      this.$refs.infiniteLoading.$emit('$InfiniteLoading:reset')
    }
  }

  get variant () {
    return (this.users.length == 0) ? 'secondary' : (this.users.length >= 100) ? 'warning' : 'primary'
  }

  get q () { return this.$route.query.q.toString() }

  search () {
    if (this.query == this.q) {
      this.onUrlChanged()
    } else {
      this.$router.push({ name: 'search-users', query: { q: this.query }})
    }
  }

  getUsers ($state: any) {
    if (!this.query) {
      $state.complete()
      return
    }

    let params: any = { query: this.query }

    if (this.users.length > 0) {
      params['offset_id'] = this.users[this.users.length - 1].id
    }

    this.$store.dispatch('searchUsers', { params: params })
      .then(({ data }) => {
        if (data.users.length > 0) {
          this.users.push(...data.users)

          $state.loaded()

          if (data.users.length < data.max_size) $state.complete()
        } else {
          $state.complete()
        }
      })
  }
}
