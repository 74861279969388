
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { mapState } from 'vuex'
import LogContainer from '@/components/logs/LogContainer.vue'
import ChurchUsers from '@/components/logs/ChurchUsers.vue'

@Component({
  components: { LogContainer, ChurchUsers },
  computed: { ...mapState(['isLoading']) },
})
export default class ChurchForm extends Vue {
  @Prop() public user!: any
  @Prop() public church!: any
  @Prop() public index!: any

  isLoading!: boolean

  churchUsers: any = []

  category = null

  get options () {
    return [
      { value: null, text: '교회 DB 업데이트', disabled: true },
      { value: 1, text: 'Type.1' },
      { value: 2, text: 'Type.2' },
      { value: 3, text: 'Type.3' },
      { value: 4, text: 'Type.4' },
      { value: 7, text: 'Type.7' },
      { value: 8, text: 'Type.8' },
      { value: 9, text: 'Type.9' }
    ]
  }

  get categoryBadgeVariant () {
    if (this.church.category >= 8) {
      return 'danger'
    } else {
      return 'info'
    }
  }

  get isUserComponent () { return this.user && this.user.id }

  get logContainerId () {
    if (this.isUserComponent) {
      return `church-users-user-${this.user.id}`
    } else {
      return `church-users-church-${this.church.id}`
    }
  }

  mounted () {
    if (!this.church || !this.church.church_name) {
      this.getChurch()
    }
  }

  getChurch () {
    if (this.isUserComponent) {
      this.$emit('reload', this.user)
    } else {
      this.$emit('reload', this.index)
    }
  }

  @Watch('category')
  createChurch () {
    if (this.category) {
      if (this.isUserComponent) {
        this.$store.dispatch('createChurch', { params: { user_id: this.user.id, category: this.category } })
          .then(() => this.getChurch())
          .finally(() => this.category = null)
      } else {
        this.$store.dispatch('updateChurch', { churchId: this.church.id, params: { category: this.category } })
          .then(() => this.getChurch())
          .finally(() => this.category = null)
      }
    }
  }

  async deleteChurch () {
    if (await this.$bvModal.msgBoxConfirm('해당 교회 정보를 삭제하시겠습니까?', { okVariant: 'dark', autoFocusButton: 'ok' })) {
      this.$store.dispatch('deleteChurch', { id: this.church.id })
        .then(() => this.getChurch())
    }
  }

  searchOnWebsite (site: string, column: string, isDongContain: boolean = false) {
    let query = ''

    if (this.isUserComponent) {
      if (column == 'church_location') {
        let value = this.user[column]

        if (!Array.isArray(value)) value = this.user.profile[column].value

        if (isDongContain) {
          query = value.join(' ')
        } else {
          query = value.slice(0, 2).join(' ')
        }        
      } else if (column == 'pastor_name') {
        query = this.user[column] + '목사'
      }

      query += ' ' + this.user.church_name
    } else {
      query = this.church[column]

      if (column == 'pastor_name') {
        query = this.church[column] + '목사'
      } else {
        query = this.church[column]
      }

      query += ' ' + this.church.church_name
    }

    if (site == 'naver') {
      window.open('https://search.naver.com/search.naver?where=nexearch&query=' + query, site, 'top=0,right=0,width=800,height=600')
    } else {
      window.open('https://google.co.kr/search?q=' + query, site, 'top=0,right=0,width=800,height=600')
    }
  }
}
