
import { Vue, Component, Watch } from 'vue-property-decorator'
import InfiniteLoading from 'vue-infinite-loading'

@Component({ props: [ 'user' ] })
export default class Couples extends Vue {
  $refs!: {
    infiniteLoading: InfiniteLoading
  }

  user!: any
  couples: any[] = []

  counts = {
    all: 0,
    sent: 0,
    received: 0,
    paired: 0
  }

  status: string = 'all'

  get categories () {
    return [
      { text: '전체', value: 'all' },
      { text: '메시지보냄', value: 'sent' },
      { text: '메시지받음', value: 'received' },
      { text: '연결됨', value: 'paired' }
    ]
  }

  @Watch('status')
  resetCouples () {
    this.couples = []
    this.$refs.infiniteLoading.$emit('$InfiniteLoading:reset')
  }

  isActive (status: string): boolean { return this.status == status }

  getCouples ($state: any) {
    let params: any = { status: this.status }

    if (this.couples.length > 0) {
      params['offset_id'] = this.couples[this.couples.length - 1].id
    }

    this.$store.dispatch('getCouples', { userId: this.user.id, params: params })
      .then(({ data }) => {
        this.counts = data.counts

        if (data.couples.length > 0) {
          this.couples.push(...data.couples)

          $state.loaded()
        } else {
          $state.complete()
        }
      })
  }
}
