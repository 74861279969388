
import { Vue, Component } from 'vue-property-decorator'

@Component({ props: ['value', 'category'] })
export default class ProfileValue extends Vue {
  value!: any
  category!: string

  get highlightQuery () {
    return [
      '임신',
      '출산',
      '미혼모',
      '주부',
      '아기',
      '애기',
      '태동',
      '아들',
      '딸',
      '한부모',
      '싱글',
      '재혼',
      '이혼',
      '돌싱',
      '사별',
      '자녀',
      '아이',
      '실패',
      '병원',
      '장애',
      '결혼'
    ]
  }

  get isHighlightableCategories () {
    return [
      'faith',
      'church_interviews',
      'place_interviews',
      'memory_interviews',
      'charm_interviews',
      'food_interviews',
      'prayer_interviews',
      'date_interviews',
      'school_interviews',
      'work_interviews',
      'vision_interview',
      'episode_interviews',
      'hope_interviews',
    ].includes(this.category)
  }

  get isArray (): boolean { return Array.isArray(this.value) }
}
