
import { Vue, Component, Watch } from 'vue-property-decorator'
import { mapState } from 'vuex'
import InfiniteLoading from 'vue-infinite-loading'
import ChurchForm from '@/components/ChurchForm.vue'

@Component({
  components: { ChurchForm },
  computed: { ...mapState(['isLoading']) }
})
export default class Churches extends Vue {
  $refs!: {
    infiniteLoading: InfiniteLoading
  }

  churches: any = []
  churchName: string = this.cn
  churchLocation: string = this.cl

  @Watch('$route', { immediate: true, deep: true })
  onUrlChanged () {
    this.churchName = this.cn
    this.churchLocation = this.cl
    this.churches = []

    if (this.$refs.infiniteLoading) {
      this.$refs.infiniteLoading.$emit('$InfiniteLoading:reset')
    }
  }

  get variant () {
    return (this.churches.length == 0) ? 'secondary' : (this.churches.length >= 100) ? 'warning' : 'primary'
  }

  get cn () { return this.$route.query.cn.toString() }
  get cl () { return this.$route.query.cl.toString() }

  search () {
    if (this.churchName == this.cn && this.churchLocation == this.cl) {
      this.onUrlChanged()
    } else {
      this.$router.push({ name: 'search-churches', query: { cn: this.churchName, cl: this.churchLocation }})
    }
  }

  getChurches ($state: any) {
    if (!this.churchName) {
      $state.complete()
      return
    }

    let params: any = { church_name: this.churchName, church_location: this.churchLocation }

    if (this.churches.length > 0) {
      params['offset_id'] = this.churches[this.churches.length - 1].id
    }

    this.$store.dispatch('searchChurches', { params: params })
      .then(({ data }) => {
        if (data.churches.length > 0) {
          this.churches.push(...data.churches)

          $state.loaded()
        } else {
          $state.complete()
        }
      })
  }

  getChurch (index: number) {
    this.$store.dispatch('getChurch', { churchId: this.churches[index].id })
      .then(({ data }) => this.$set(this.churches, index, data))
  }
}
