
import { Vue, Component } from 'vue-property-decorator'
import { mapState } from 'vuex'

@Component({
  computed: { ...mapState(['isLoading']) },
  props: [ 'focusedCategory', 'categories', 'counts', 'list' ]
})
export default class CategoryBreadcrumb extends Vue {
  list!: any
  counts!: any
  isLoading!: boolean
  focusedCategory!: string

  isActive (category: any) { return this.focusedCategory == category }

  reload () {
    if ( ! this.isLoading) this.$emit('reload')
  }
}
