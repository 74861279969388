
import { Vue, Component, Watch } from 'vue-property-decorator'
import { BModal } from 'bootstrap-vue'
import Certificate from '@/components/Certificate.vue'

@Component({
  components: { Certificate, },
  props: ['userId', 'certificate'],
})
export default class CertificateContainer extends Vue {
  $refs!: {
    confirmReview: BModal
  }

  userId!: number
  certificate!: any

  reviewStatus: any = null

  confirmCertificateReview (certificate: any, status: string) {
    this.reviewStatus = status
    this.$refs.confirmReview.show()
  }

  reviewCertificate () {
    this.$store.dispatch('reviewCertificate', {
        id: this.certificate.id,
        params: {
          status: this.reviewStatus,
          rejection_comment: this.certificate.rejection_comment,
          review_token_confirmation: this.certificate.review_token,
        }
      })
      .then(() => {
        this.$emit('reload')
        this.$refs.confirmReview.hide()
      })
      .catch(({ response }) => {
        this.$bvToast.toast(response.data.message || response, {
          title: '심사 오류',
          variant: 'danger'
        })
      })
  }

  reloadUser () {
    this.$emit('reload')
  }
}
