
import { Vue, Component, Watch } from 'vue-property-decorator'
import { BDropdown } from 'bootstrap-vue'
import { mapGetters } from 'vuex'

@Component({
  computed: { ...mapGetters({ photoUrl: "photoUrl" }) },
  props: ['userId', 'photo', 'index', 'active', 'isCoupleComponent', 'isReviewComponent', 'isShownPreviousPhoto'],
})
export default class PhotoFrame extends Vue {
  $refs!: {
    dropdown: BDropdown
  }

  userId!: number
  photo!: any
  active!: boolean
  isCoupleComponent!: boolean
  isShownPreviousPhoto!: boolean
  isVerified = this.photo.is_verified
  isRemoved: boolean = false

  /**
   * @returns {Option[]} 반려 유형 목록
   */
  get rejectionTemplates () {
    return this.$store.getters.getRejectionTemplates(this.photo.category)
  }

  /**
   * @returns {string} 사진 프레임 비율
   */
  get photoAspect () {
    return this.photo.category == 'idcards' ? '16:9' : '1:1'
  }

  /**
   * @returns {boolean} 심사 가능 여부
   */
  get isReviewable () {
    return ['on_review', 'wait_reject', 'wait_approve', 'wait_review'].includes(this.photo.status) && ! this.photo.deleted_at
  }

  /**
   * @returns {boolean} 인증 처리 가능 여부
   */
  get isVerifiable () {
    return ['wait_approve', 'approved'].includes(this.photo.status) && this.photo.category == 'selfies'
  }

  /**
   * @returns {boolean} 드롭다운 메뉴 활성화 여부
   */
  get isDropdownActive () {
    return this.active && ! this.isShownPreviousPhoto
  }

  /**
   * @returns {boolean} 이전 사진 확인 가능 여부
   */
  get isPreviousPhotoVisible () {
    return ['on_review', 'wait_reject', 'wait_approve', 'wait_review', 'rejected'].includes(this.photo.status) && this.photo.category == 'selfies' && ! this.photo.deleted_at
  }

  /**
   * @description 드롭다운 펼치기
   */
  showDropdown () {
    if (this.isDropdownActive) this.$refs.dropdown.show()
  }

  /**
   * @param {string} url 사진 URL
   * @description 새 탭에서 사진 원본사이즈로 보기
   */
  showPhoto (url: string) {
    window.open(this.$store.getters.photoUrl(url), '_blank')
  }

  /**
   * @param {string} status 처리할 스테이터스
   * @description 사진 심사 처리, 페이지를 새로고침 할 경우도 있으므로 심사 처리는 상위 컴포넌트로 전달
   */
  reviewPhoto (status: string) {
    this.$refs.dropdown.hide()
    this.$emit('review-photo', this.userId, this.photo, status)
  }

  /**
   * @description 지정한 사진을 메인사진으로 변경, 새로고침이 필요하므로 상위 컴포넌트로 전달
   */
  async setMainPhoto () {
    if (await this.$bvModal.msgBoxConfirm('해당 사진을 메인사진으로 변경하시겠습니까?', { okVariant: 'info', autoFocusButton: 'ok' })) {
      this.$refs.dropdown.hide()
      this.$emit('set-main-photo', this.userId, this.photo);
    }
  }

  /**
   * @description 지정한 사진을 맨 뒤로 변경, 새로고침이 필요하므로 상위 컴포넌트로 전달
   */
  async setLastPhoto () {
    if (await this.$bvModal.msgBoxConfirm('해당 사진을 맨 뒤로 보내시겠습니까?', { okVariant: 'info', autoFocusButton: 'ok' })) {
      this.$refs.dropdown.hide()
      this.$emit('set-last-photo', this.userId, this.photo);
    }
  }

  @Watch('isVerified')
  toggleVerified () {
    if (this.isCoupleComponent) {
      this.$bvToast.toast("소개 페이지에선 변경이 불가능합니다.", {
        title: '알림',
        variant: 'danger'
      })
    } else {
      let verifiedAt = this.isVerified ? new Date() : null

      this.$store.dispatch('reviewPhoto', { id: this.photo.id, params: { verified_at: verifiedAt } })
        .then(() => {
          this.$refs.dropdown.hide()
          this.$set(this.photo, 'is_verified', this.isVerified)
        })
    }
  }

  /**
   * @description 사진 파일 삭제
   */
  async removePhoto () {
    if (await this.$bvModal.msgBoxConfirm('해당 사진을 삭제하시겠습니까? (* 삭제시 복원이 불가능 합니다.)', { okVariant: 'dark', autoFocusButton: 'ok' })) {
      this.$store.dispatch('removePhoto', { userId: this.userId, id: this.photo.id })
        .then(() => {
          this.$bvToast.toast(`[Photo#${this.photo.id}] 사진이 삭제되었습니다.`, {
            title: '사진 삭제',
            variant: 'info'
          })

          this.isRemoved = true
          this.$refs.dropdown.hide()
          this.$emit('reload')
        })
        .catch(({ response }) => {
          this.$bvToast.toast(response.data.message || response, {
            title: '삭제 오류',
            variant: 'danger'
          })
        })
    }
  }

  /**
   * @description 사진 파일 삭제 (완전 삭제)
   */
  async deletePhoto () {
    if (await this.$bvModal.msgBoxConfirm('해당 사진 파일을 완전히 삭제하시겠습니까? (* 파일 삭제시 복원이 불가능 합니다.)', { okVariant: 'dark', autoFocusButton: 'ok' })) {
      this.$store.dispatch('deletePhoto', { userId: this.userId, id: this.photo.id })
        .then(() => {
          this.$bvToast.toast(`[Photo#${this.photo.id}] 사진 파일이 완전히 삭제되었습니다.`, {
            title: '사진 삭제',
            variant: 'info'
          })

          this.$refs.dropdown.hide()
          this.$emit('reload')
        })
        .catch(({ response }) => {
          this.$bvToast.toast(response.data.message || response, {
            title: '파일 제거 오류',
            variant: 'danger'
          })
        })
    }
  }
}
