
import { Vue, Component } from 'vue-property-decorator'
import { mapGetters } from 'vuex'

@Component({
  computed: {
    ...mapGetters({
      currentUser: "currentUser",
      googleAuthData: "googleAuthData",
      isProductionAPI: "isProductionAPI",
      isProbationAdmin: "isProbationAdmin",
    })
  }
})
export default class Navbar extends Vue {
  currentUser!: any
  googleAuthData!: any
  isProductionAPI!: boolean

  query: string = ''

  signOut () {
    this.$store.dispatch('signOut')
      .then(() => this.$router.replace({ path: '/login' }))
  }

  search () {
    this.$router.push({ name: 'search-users', query: { q: this.query }})
    this.$root.$emit('bv::toggle::collapse', 'nav-collapse')
    this.query = ''
  }

  get isSearchPage () {
    return this.$route.name == 'search-users'
  }

  linkWithGoogle () {
    this.$store.dispatch('linkWithGoogle')
      .then(() => {
        this.$bvModal.show('link-google-account-complete')
        setTimeout(() => window.location.reload(), 3000)
      })
      .catch((error) => {
        window.console.error(error)
        this.$bvModal.show('link-google-account-failed')
      })
  }

  async unlinkWithGoogle () {
    if (await this.$bvModal.msgBoxConfirm('구글 계정 연동을 해제하시겠습니까?', { okVariant: 'dark', autoFocusButton: 'ok' })) {
      this.$store.dispatch('unlinkWithGoogle')
        .then(() => {
          this.$bvModal.show('unlink-google-account-complete')
          setTimeout(() => window.location.reload(), 3000)
        })
        .catch((error) => {
          window.console.error(error)
          this.$bvModal.show('unlink-google-account-failed')
        })
    }
  }
}
