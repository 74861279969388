
import { Vue, Component } from 'vue-property-decorator'
import UserBadges from '@/components/UserBadges.vue'

@Component({
  components: { UserBadges },
  props: [ 'user' ]
})
export default class UserCard extends Vue {
  user!: any

  get profileStatusChangedAtTitle () {
    switch (this.user.profile_status) {
      case 'on_review': case 'wait_reject': case 'wait_approve': return '심사 제출'
      case 'rejected': return '심사 반려'
      case 'approved': return '심사 승인'
      default: return null
    }
  }
}
