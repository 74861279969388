
import { Vue, Component } from 'vue-property-decorator'
import PhotoFrame from '@/components/PhotoFrame.vue'
import InfiniteLoading from 'vue-infinite-loading'

@Component({
  components: { PhotoFrame },
  props: [ 'user' ],
})
export default class Photos extends Vue {
  $refs!: {
    infiniteLoading: InfiniteLoading
  }

  user!: any
  photos: any[] = []

  resetPhotos () {
    this.photos = []
    this.$refs.infiniteLoading.$emit('$InfiniteLoading:reset')
  }

  getPhotos ($state: any) {
    let params: any = {}

    if (this.photos.length > 0) {
      params['offset_id'] = this.photos[this.photos.length - 1].id
    }

    this.$store.dispatch('getUserPhotos', { userId: this.user.id, params: params })
      .then(({ data }) => {
        if (data.photos.length > 0) {
          this.photos.push(...data.photos)

          $state.loaded()
        } else {
          $state.complete()
        }
      })
  }
}
