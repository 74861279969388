
import { Vue, Component, Watch } from 'vue-property-decorator'

@Component({ props: ['couple', 'userId'] })
export default class CoupleMessage extends Vue {
  couple!: any
  userId!: number

  isViewMode: boolean = true

  editValue!: string

  get isTarget() { return this.couple.target_user.id == this.userId }
  get prefix () { return this.isTarget ? 'target_' : '' }
  get targetPrefix () { return this.isTarget ? '' : 'target_' }
  get sentAt () { return this.couple[this.prefix + 'message_sent_at'] }
  get readAt () { return this.couple[this.targetPrefix + 'message_checked_at'] }
  get message () { return this.couple[this.prefix + 'message'] }
  get messageType () { return this.couple[this.prefix + 'message_type'] }
  get gender () { return this.couple[this.prefix + 'gender'] }
  get targetGender () { return this.couple[this.targetPrefix + 'gender'] }

  toggleViewMode () {
    this.isViewMode = ! this.isViewMode
  }

  @Watch('isViewMode')
  onViewModeChanged () {
    if (this.isViewMode) return

    this.editValue = this.message
  }

  async updateCouple () {
    if (this.editValue && this.editValue != this.message) {
      let params: any = {}

      params[`${this.gender}_message`] = this.editValue

      if (await this.$bvModal.msgBoxConfirm('메시지를 수정하시겠습니까? (* 롤백이 불가능합니다.)', { okVariant: 'dark', autoFocusButton: 'ok' })) {
        this.$store.dispatch('updateCouple', { coupleId: this.couple.id, params: params })
          .then(() => {
            this.toggleViewMode()
            this.$set(this.couple, this.prefix + 'message', this.editValue)
          })
          .catch(({ response }) => {
            this.$bvToast.toast(response.data.message || response, {
              title: '메시지 수정 오류',
              variant: 'danger'
            })
          })
      }
    } else {
      this.toggleViewMode()
    }
  }
}
