
import { Vue, Component, Watch } from 'vue-property-decorator'
import { mapState } from 'vuex'
import { Editor, EditorContent, EditorMenuBar } from 'tiptap'
import {
  HardBreak,
  Heading,
  OrderedList,
  BulletList,
  ListItem,
  TodoItem,
  TodoList,
  Bold,
  Code,
  Italic,
  Link,
  Image,
  Strike,
  Underline,
  History,
} from 'tiptap-extensions'

@Component({
  computed: { ...mapState(['isLoading', 'currentUser']) },
  components: { EditorContent, EditorMenuBar }
})
export default class Notice extends Vue {
  notice: any = {
    id: null,
    status: 'draft',
    title: '',
    content: '',
    created_at: '',
    updated_at: '',
  }

  editor?: Editor
  isEditorInitialized = false

  mounted () {
    this.editor = new Editor({
      extensions: [
        new BulletList(),
        new HardBreak(),
        new Heading({ levels: [1, 2, 3] }),
        new ListItem(),
        new OrderedList(),
        new TodoItem(),
        new TodoList(),
        new Link(),
        new Image(),
        new Bold(),
        new Code(),
        new Italic(),
        new Strike(),
        new Underline(),
        new History(),
      ],
      onInit: () => {
        this.isEditorInitialized = true
      },
      onUpdate: (editor: Editor) => {
        this.notice.content = editor.getHTML()
      }
    })

    if (this.noticeId) this.getNotice()
  }

  beforeDestroy () {
    if (this.editor) this.editor.destroy()
  }

  get noticeId () {
    let noticeId = this.$route.params.noticeId
    return noticeId == 'new' ? undefined : noticeId
  }

  get statusOptions () {
    return [
      { text: '임시 저장', value: 'draft' },
      { text: '공개', value: 'released' }
    ]
  }

  @Watch('noticeId')
  onNoticeIdChanged () {
    if (this.noticeId) this.getNotice()
  }

  getNotice () {
    this.$store.dispatch('getNotice', { id: this.noticeId })
      .then(({ data }) => {
        this.notice = data
        if (this.editor) this.editor.setContent(this.notice.content)
      })
  }

  async onSubmit () {
    if (this.noticeId) {
      if (await this.$bvModal.msgBoxConfirm('해당 공지사항을 수정하시겠습니까?', { okVariant: 'primary', autoFocusButton: 'ok' })) {
        this.$store.dispatch('updateNotice', { id: this.noticeId, params: this.notice })
          .then(this.backToList)
      }
    } else {
      if (await this.$bvModal.msgBoxConfirm('작성한 공지사항을 등록하시겠습니까?', { okVariant: 'primary', autoFocusButton: 'ok' })) {
        this.$store.dispatch('createNotice', { params: this.notice })
          .then(this.backToList)
      }
    }
  }

  async onDestroy () {
    if (this.noticeId) {
      if (await this.$bvModal.msgBoxConfirm('해당 공지사항을 정말 삭제하시겠습니까?', { okVariant: 'danger', autoFocusButton: 'ok' })) {
        this.$store.dispatch('deleteNotice', { id: this.noticeId })
          .then(this.backToList)
      }
    }
  }

  backToList () {
    this.$router.replace({ name: 'notices' })
  }

  setLink (command: CallableFunction) {
    if ( ! this.editor) return

    const state = this.editor.state

    // get marks, if any from selected area
    const { from, to } = state.selection

    if (from == to) {
      return this.$bvToast.toast("링크를 지정할 영역을 선택해주세요.", {
          title: '알림',
          variant: 'danger'
        })
    }

    let url: any = ''
    let marks: any = []

    state.doc.nodesBetween(from, to, (node: any) => {
      marks = [...marks, ...node.marks]
    })

    const mark = marks.find((markItem: any) => markItem.type.name === 'link')

    if (mark && mark.attrs.href) url = mark.attrs.href

    url = prompt('링크를 입력해주세요.', url)

    if (url == null) return;

    if (url == '') {
      command({ href: null })
    } else {
      command({ href: url, target: '_blank' })
    }
  }

  setImage (command: CallableFunction) {
    const src = prompt('추가할 이미지의 URL을 입력해주세요.')
    if (src !== null && src != '') {
      command({ src })
    }
  }
}
