
import { Vue, Component, Watch } from 'vue-property-decorator'
import CategoryBreadcrumb from '@/components/CategoryBreadcrumb.vue'
import Profile from '@/components/Profile.vue'
import UserBadges from '@/components/UserBadges.vue'
import UserGradeGroup from '@/components/UserGradeGroup.vue'
import Certificate from '@/components/Certificate.vue'
import ChurchForm from '@/components/ChurchForm.vue'

@Component({
  components: {
    Certificate,
    Profile,
    CategoryBreadcrumb,
    UserBadges,
    UserGradeGroup,
    ChurchForm
  }
})
export default class Certificates extends Vue {
  certificates: any = []
  counts: any = {
    education: 0,
    workplace: 0,
    church: 0,
  }

  mounted () {
    this.onCategoryChanged()
  }

  get categories () {
    return [
      { key: 'education', text: '학교인증', route: { name: 'review-certificates', params: { category: 'education' } } },
      { key: 'workplace', text: '직장인증', route: { name: 'review-certificates', params: { category: 'workplace' } } },
      { key: 'church', text: '교회인증', route: { name: 'review-certificates', params: { category: 'church' } } },
    ]
  }

  get category () { return (this.$route.params.category || this.categories[0].key).replace('-', '_') }

  @Watch('category')
  onCategoryChanged () {
    this.certificates = []

    if (this.category) {
      this.$store.dispatch('getWaitReviewCertificates', { params: {category: this.category}})
        .then(({data}) => {
          this.certificates = data.certificates
          this.counts = data.counts
        })
    }
  }

  /**
   * @method openUser
   * @returns {Void}
   * @description 
   */
  openUser (id: string) {
    this.$router.push({ name: 'user', params: { userId: id } })
  }

  reviewCertificate (certificate: any, status: string) {
    this.$set(certificate, 'status', status)
  }

  async confirmCertificateReview (certificate: any) {
    if (await this.$bvModal.msgBoxConfirm('심사를 반영하시겠습니까? (* 반영 이후엔 되돌릴 수 없습니다.)', { okVariant: 'dark', autoFocusButton: 'ok' })) {
      if (['wait_reject', 'wait_approve'].includes(certificate.status)) {
        this.$store.dispatch('reviewCertificate', {
            id: certificate.id,
            params: {
              status: certificate.status,
              rejection_comment: certificate.rejection_comment,
              review_token_confirmation: certificate.review_token,
            }
          })
          .then(async () => {
            this.$bvToast.toast(`[User#${certificate.user_id}] 심사가 반영되었습니다.`, {
              title: '알림',
              variant: 'info'
            })

            this.onCategoryChanged()
          })
          .catch(({ response }) => {
            this.$bvToast.toast(response.data.message || response, {
              title: '심사 오류',
              variant: 'danger'
            })
          })
      }
    }
  }

  getChurch (user: any) {
    this.$store.dispatch('getUserChurch', { userId: user.id })
      .then(({ data }) => this.$set(user, 'church', data))
  }
}
