
import { Vue, Component } from 'vue-property-decorator'

@Component({ props: [ 'user' ] })
export default class CouplePasses extends Vue {
  user!: any
  couplePasses: any[] = []

  getCouplePasses ($state: any) {
    let params: any = {}

    if (this.couplePasses.length > 0) {
      params['offset_id'] = this.couplePasses[this.couplePasses.length - 1].id
    }

    this.$store.dispatch('getCouplePasses', { userId: this.user.id, params: params })
      .then(({ data }) => {
        if (data.couple_passes.length > 0) {
          this.couplePasses.push(...data.couple_passes)

          $state.loaded()
        } else {
          $state.complete()
        }
      })
  }

  async deleteCouplePass (id: any) {
    if (await this.$bvModal.msgBoxConfirm('해당 정기권을 삭제하시겠습니까?', { okVariant: 'dark', autoFocusButton: 'ok' })) {
      this.$store.dispatch('deleteCouplePass', { userId: this.user.id, id: id })
        .then(() => {
          let index = this.couplePasses.findIndex((couplePass) => couplePass.id == id)

          this.couplePasses[index]['deleted_at'] = new Date()
        })
    }
  }
}
