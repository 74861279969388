
import { Vue, Component } from 'vue-property-decorator'
import { mapState } from 'vuex'
import UserCard from '@/components/UserCard.vue'

@Component({
  props: [ 'user' ],
  computed: { ...mapState(['isLoading']) },
  components: { UserCard }
})
export default class UserDuplicates extends Vue {
  user!: any
  duplicateUsers: any[] = []

  mounted () {
    this.getDuplicateUsers()
  }

  getDuplicateUsers () {
    this.$store.dispatch('getUserDuplicates', { id: this.user.id })
      .then(({ data }) => this.duplicateUsers = data.users.reverse())
  }
}
