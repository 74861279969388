
import { Vue, Component } from 'vue-property-decorator'
import { BDropdown } from 'bootstrap-vue'

@Component({
  props: ['userId', 'certificate', 'isReviewComponent']
})
export default class Certificate extends Vue {
  $refs!: {
    dropdown: BDropdown
  }

  userId!: number
  certificate!: any
  isReviewComponent!: boolean

  /**
   * @returns {Option[]} 반려 유형 목록
   */
  get rejectionTemplates () {
    return this.$store.getters.getRejectionTemplates(this.certificate.category)
  }

  setRejectionComment (rejectionTemplate: any) {
    this.$set(this.certificate, 'rejection_comment', rejectionTemplate.value)
  }

  /**
   * @returns {boolean} 심사 가능 여부
   */
  get isReviewable () {
    return ['wait_reject', 'wait_approve', 'wait_review'].includes(this.certificate.status)
  }

  /**
   * @returns {string} 사진 프레임 비율
   */
  get photoAspect () {
    return this.certificate.category == 'identification' ? '16:9' : '210:297'
  }

  /**
   * @param {string} url 사진 URL
   * @description 새 탭에서 사진 원본사이즈로 보기
   */
  showPhoto (url: string) {
    window.open(this.$store.state.baseURL + url, '_blank')
  }

  /**
   * @param {string} status 처리할 스테이터스
   * @description 인증정보 심사처리, 페이지를 새로고침 할 경우도 있으므로 심사 처리는 상위 컴포넌트로 전달
   */
  reviewCertificate (status: string) {
    this.$refs.dropdown.hide()
    this.$emit('review-certificate', this.certificate, status)
  }

  reloadCertificate () {
    this.$emit('reload')
  }

  /**
   * @description 드롭다운 펼치기
   */
  showDropdown () {
    this.$refs.dropdown.show()
  }

  /**
   * @description 인증정보 초기화하기
   */
  async revokeCertificate () {
    if (await this.$bvModal.msgBoxConfirm('해당 인증정보를 초기화(삭제)하시겠습니까? (* 초기화시 데이터 복원이 불가능 합니다.)', { okVariant: 'dark', autoFocusButton: 'ok' })) {
      this.$store.dispatch('deleteCertificate', { userId: this.userId, id: this.certificate.id })
        .then(() => {
          this.$refs.dropdown.hide()
          this.$emit('reload')
        })
    }
  }
}
