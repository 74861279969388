
import { Vue, Component, Watch } from 'vue-property-decorator'
import { BDropdown, BModal } from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import PhotoFrame from '@/components/PhotoFrame.vue'

@Component({
  components: { PhotoFrame },
  computed: { ...mapGetters({ photoUrl: "photoUrl" }) },
  props: ['userId', 'photos', 'category', 'isCoupleComponent'],
})
export default class Photos extends Vue {
  $refs!: {
    confirmReview: BModal
    photoFrames: PhotoFrame[]
  }

  category!: string
  userId!: number
  photos!: any[]

  focus: number = 0
  isShownPreviousPhoto: boolean = false

  currentPhoto: any = {}

  created () {
    window.addEventListener('keypress', this.onKeyPressed)
  }

  destroyed () {
    window.removeEventListener('keypress', this.onKeyPressed)
  }

  onKeyPressed (event: KeyboardEvent) {
    const hotKeys = [49, 50, 51, 52, 53] // 1, 2, 3, 4, 5

    if (hotKeys.includes(event.keyCode)) {
      const index = hotKeys.indexOf(event.keyCode)

      if (this.photos && this.photos[index]) this.changeFocus(index, false)
    }
  }

  /**
   * @returns {Option[]} 반려 유형 목록
   */
  get rejectTypes () {
    return this.$store.getters.getRejectionTemplates(this.category)
  }

  changeFocus (index: number, showPreviousPhoto: boolean) {
    this.focus = index
    this.isShownPreviousPhoto = showPreviousPhoto
  }

  confirmPhotoReview (userId: number, photo: any, status: string) {
    this.currentPhoto = JSON.parse(JSON.stringify(photo)) // Deepcopy

    this.currentPhoto.status = status

    if (photo.status == 'wait_review') {
      // 적용 전 status 가 wait_review == 승인된 회원
      this.$refs.confirmReview.show()
    } else {
      this.reviewPhoto()
    }
  }

  reviewPhoto () {
    this.$store.dispatch('reviewPhoto', {
      id: this.currentPhoto.id,
      params: {
        status: this.currentPhoto.status,
        rejection_comment: this.currentPhoto.rejection_comment,
        review_token_confirmation: this.currentPhoto.review_token
      }
    }).then(() => {
        this.$emit('reload')
        this.$refs.confirmReview.hide()
      })
  }

  setMainPhoto (userId: number, photo: any) {
    let photoIds = this.photos.map((p) => p.id)
    photoIds.sort((a, b) => a == photo.id ? -1 : b == photo.id ? 1 : 0)

    this.$store.dispatch('reorderPhoto', {
      userId: userId,
      params: { category: photo.category, reordered_ids: photoIds }
    }).then(() => this.$emit('reload'))
  }

  setLastPhoto (userId: number, photo: any) {
    let photoIds = this.photos.map((p) => p.id)
    photoIds.sort((a, b) => a == photo.id ? 1 : b == photo.id ? -1 : 0)

    this.$store.dispatch('reorderPhoto', {
      userId: userId,
      params: { category: photo.category, reordered_ids: photoIds }
    }).then(() => this.$emit('reload'))
  }

  reloadUser () {
    this.$emit('reload')
  }

  isPreviousPhotoVisible (photo: any) {
    return ['on_review', 'wait_reject', 'wait_approve', 'wait_review', 'rejected'].includes(photo.status) && photo.category == 'selfies' && ! photo.deleted_at
  }

  showPhoto (url: string) {
    this.$refs.photoFrames[0].showPhoto(url)
  }

  showDropdown (index: number) {
    this.$refs.photoFrames[index].showDropdown()
  }
}
