
import { Vue, Component } from 'vue-property-decorator'

@Component({ props: [ 'user' ] })
export default class Notifications extends Vue {
  user!: any
  notifications: any[] = []

  getNotifications ($state: any) {
    let params: any = { term: 'all' }

    if (this.notifications.length > 0) {
      params['offset_id'] = this.notifications[this.notifications.length - 1].id
    }

    this.$store.dispatch('getNotifications', { userId: this.user.id, params: params })
      .then(({ data }) => {
        if (data.notifications.length > 0) {
          this.notifications.push(...data.notifications)

          $state.loaded()
        } else {
          $state.complete()
        }
      })
  }

  async deleteNotification (id: any) {
    if (await this.$bvModal.msgBoxConfirm('해당 알림을 삭제하시겠습니까?', { okVariant: 'dark', autoFocusButton: 'ok' })) {
      this.$store.dispatch('deleteNotification', { id: id })
        .then(() => {
          let index = this.notifications.findIndex((notification) => notification.id == id)

          this.notifications.splice(index, 1)
        })
    }
  }
}
