
import { Vue, Component } from 'vue-property-decorator'

@Component({ props: ['user'] })
export default class CoupleForm extends Vue {
  user!: any

  preventSubmit: boolean = false

  targetUserId: string = ''
  offsetHour: string = '0'

  onSubmit () {
    this.preventSubmit = true

    this.$store.dispatch('createCouple', { userId: this.user.id, params: { target_user_id: this.targetUserId, offset_hour: this.offsetHour } })
      .then(() => {
        this.$bvToast.toast('테스트 소개 생성이 정상적으로 완료되었습니다.', {
          title: '소개 생성',
          variant: 'success'
        })
        this.onReset()
      })
      .catch(({ response }) => {
        this.$bvToast.toast(response.data.message || response, {
          title: '소개 생성 오류',
          variant: 'danger'
        })
      })
      .finally(() => { this.preventSubmit = false })
  }

  onReset () {
    this.targetUserId = ''
    this.offsetHour = '0'
    this.$emit('reload')
  }
}
