import Vue from 'vue'

export function capitalize (value: any): string {
  if (!value) return ''
  value = value.toString()
  return value.charAt(0).toUpperCase() + value.slice(1)
}

// CSS Class 에 적용시키기 위해 underscore 를 전부 dash 로 바꿈
export function kebabcase (value: any): string {
  return value.toString().replace(/[_]/g, '-')
}

// Status Alias
export function reviewStatus (value: string): string {
  switch(value) {
    case 'pending': return '입력 중'
    case 'on_review': case 'wait_review': return '심사 대기'
    case 'wait_reject': return '반려'
    case 'wait_approve': return '승인'
    case 'rejected': return '반려 됨'
    case 'approved': return '승인 됨'
    default: return '미지정'
  }
}

// 거주지 Alias
export function residence (value: string): string {
  return ['서북권','서남권','동북권','동남권'].includes(value) ? '서울 ' + value : ['서북부','동북부','서남부','동남부'].includes(value) ? '경기 ' + value : value
}

// 성별 Alias
export function gender (value: string): string {
  return (value == 'male') ? '남성' : (value == 'female') ? '여성' : ''
}

export function logValue (value: any, category: string): string {
  if (category.endsWith('_at')) {
    return value ? Vue.filter('moment')(value, 'LLL') : String(value)
  } else {
    return String(value)
  }
}

// 입력값 스트링 변환
export function profileValue (value: any, category: string): string {
  switch (category) {
    case 'residence':
      return ['서북권','서남권','동북권','동남권'].includes(value) ? '서울 ' + value : ['서북부','동북부','서남부','동남부'].includes(value) ? '경기 ' + value : value
    case 'gender':
      return (value == 'male') ? '남성' : (value == 'female') ? '여성' : ''
    case 'birthday':
      return value ? Vue.filter('moment')(value, 'LL') : value
    case 'church_location':
    case 'occupation_groups':
      return Array.isArray(value) ? value.join(' ') : value
    case 'personality_group':
      switch (value) {
        case 'A': return '베드로 유형'
        case 'B': return '바울 유형'
        case 'C': return '아브라함 유형'
        case 'D': return '모세 유형'
        default: return '미지정'
      }
    default:
      return Array.isArray(value) ? value.filter(String).join(", ") : value
  }
}

export function profileLabel (value: string): string {
  switch(value) {
    case 'phone_number': return '연락처'
    case 'nickname': return '닉네임'
    case 'fullname': return '이름'
    case 'birthday': return '생년월일'
    case 'gender': return '성별'
    case 'personality_group': return '성격유형'
    case 'blood_group': return '혈액형'
    case 'occupation_groups': return '직업군'
    case 'residence': return '거주지'
    case 'church_name': return '교회명'
    case 'pastor_name': return '담임목사'
    case 'church_location': return '교회주소'
    case 'idcards': return '신분증'
    case 'selfies': return 'Selfies'
    case 'lifelogs': return 'Lifegrams'
    case 'faith': return 'Faith'
    case 'church_interviews': return 'Church'
    case 'place_interviews': return 'Place'
    case 'memory_interviews': return 'Memory'
    case 'charm_interviews': return 'Charm'
    case 'food_interviews': return 'Food'
    case 'prayer_interviews': return 'Prayer'
    case 'date_interviews': return 'Date'
    case 'school_interviews': return 'School'
    case 'work_interviews': return 'Work'
    case 'vision_interview': return 'Vision'
    case 'episode_interviews': return 'Episode'
    case 'hope_interviews': return 'Hope'
    case 'interest_keywords': return '취미/관심사'
    case 'movie_keywords': return '영화'
    case 'music_keywords': return '음악'
    case 'tv_keywords': return 'TV프로'
    case 'leports_keywords': return '운동'
    case 'career_grade': return '직업 급간'
    case 'education_grade': return '학력 급간'
    case 'charming_grade': return '매력 급간'
    case 'faith_grade': return '신앙 급간'
    case 'education': return '학교인증'
    case 'workplace': return '직장인증'
    case 'church': return '교회인증'
    default: return '미지정'
  }
}

export function churchStatus (value: string): string {
  switch(value) {
    case 'pastor_name': return '담임목사 일치'
    case 'church_location': return '교회주소 일치'
    case 'all': return '완전일치'
    default: return '미등록'
  }
}

export function coupleCategory (category: string, type: string): string {
  const index = type == 'text' ? 0 : 1

  switch(category) {
    case 'test_match': return ['테스트', 'info'][index]
    case 'charming_match': return ['호감친구', 'warning'][index]
    case 'newcomer_match': return ['새친구', 'primary'][index]
    case 'ministry_match': return ['사역자', 'light'][index]
    case 'order_match': return ['맞춤소개', 'info'][index]
    case 'blind_match': return ['블라인드', 'dark'][index]
    case 'keyword_match': return ['취향매칭', 'primary'][index]
    case 'yesagain_match': return ['예스어게인', 'light'][index]
    case 'favor_sent':
    case 'favor_received':
    case 'favor_unknown': return ['세렌디피티', 'info'][index]
    case 'recreated_match': return ['호감히스토리', 'warning'][index]
    default: return ['정규소개', 'secondary'][index]
  }
}

export function coupleMessageType (messageType: string, type: string): string {
  const index = type == 'text' ? 0 : 1

  switch(messageType) {
    case 'today_default': return ['기본 Yes', 'secondary'][index]
    case 'today_plus': return ['커플 Yes', 'primary'][index]
    case 'today_pass': return ['정기권 Yes', 'warning'][index]
    case 'history_default': return ['after Yes', 'info'][index]
    case 'history_plus': return ['after 커플 Yes', 'primary'][index]
    case 'reply_plus': return ['커플 Yes 답장', 'secondary'][index]
    case 'reply_default': return ['기본 Yes 답장', 'secondary'][index]
    case 'reply_pass': return ['정기권 Yes 답장', 'secondary'][index]
    case 'today_plus_returned': return ['커플 Yes (환급됨)', 'dark'][index]
    case 'history_plus_returned': return ['after 커플 Yes (환급됨)', 'dark'][index]
    default: return ['(미지정)', 'light'][index]
  }
}
