
import { Vue, Component } from 'vue-property-decorator'
import { mapState } from 'vuex'
import UserCard from '@/components/UserCard.vue'

@Component({
  components: {
    UserCard
  },
  computed: { ...mapState(['isLoading']) },
})
export default class SelfiesAttentionMarkedUsers extends Vue {
  userIds: any = []
  users: any = []

  getSelfiesAttentionMarkedUsers ($state: any) {
    this.$store.dispatch('getSelfiesAttentionMarkedUsers', { params: { offset: this.users.length }})
      .then(({data}) => {
        if (data.users.length > 0) {
          // 리스트에 있는 데이터 ID값 추출
          this.userIds = this.users.map((user: any) => user.id)

          // 이미 리스트에 있는 데이터는 중복으로 노출되지 않도록 필터
          this.users.push(...data.users.filter((user: any) => this.userIds.indexOf(user.id) === -1))

          $state.loaded()
        } else {
          $state.complete()
        }
      })
  }
}
