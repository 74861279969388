
import { Vue, Component, Watch } from 'vue-property-decorator'
import InfiniteLoading from 'vue-infinite-loading'

@Component({ props: [ 'user' ] })
export default class UserFilters extends Vue {
  $refs!: {
    infiniteLoading: InfiniteLoading
  }

  user!: any
  userFilters: any[] = []

  category: string = 'church_filter'

  get categories () {
    return [
      { text: '교회', value: 'church_filter' },
      { text: '연락처', value: 'contacts_filter' },
      { text: '이름', value: 'fullname_filter' }
    ]
  }

  @Watch('category')
  resetCouples () {
    this.userFilters = []
    this.$refs.infiniteLoading.$emit('$InfiniteLoading:reset')
  }

  isActive (category: string): boolean { return this.category == category }

  getCoins ($state: any) {
    let params: any = { category: this.category }

    if (this.userFilters.length > 0) {
      params['offset_id'] = this.userFilters[this.userFilters.length - 1].id
    }

    this.$store.dispatch('getUserFilters', { userId: this.user.id, params: params })
      .then(({ data }) => {
        if (data.user_filters.length > 0) {
          this.userFilters.push(...data.user_filters)

          $state.loaded()
        } else {
          $state.complete()
        }
      })
  }
}
