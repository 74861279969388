
import { Vue, Component } from 'vue-property-decorator'

@Component({ props: ['user'] })
export default class NotificationForm extends Vue {
  user!: any

  notification: any = this.defaultNotification
  preventSubmit: boolean = false

  get icons () {
    return [
      { text: '종(기본)', value: 'noti_bell' },
      { text: '초대장', value: 'noti_invite' },
      { text: '달란트', value: 'noti_talent' },
      { text: '필터', value: 'noti_filter' },
      { text: '제출', value: 'noti_submission' },
      { text: '승인', value: 'noti_approval' },
      { text: '반려', value: 'noti_rejection' },
      { text: '전구', value: 'noti_bulb' },
      { text: '편지', value: 'noti_letter' },
      { text: '하트', value: 'noti_heart' },
    ]
  }

  get options () {
    return [
      { text: '알림함 저장', value: 'message' },
      { text: 'PUSH', value: 'fcm_body' },
    ]
  }

  get defaultNotification () {
    let notification = {
      user_ids: '',
      title: '시스템 알림',
      icon: 'noti_bell',
      message: '',
      notifier: ['message']
    }

    if (this.user) notification.user_ids = "," + this.user.id

    return notification
  }

  onSubmit () {
    this.preventSubmit = true

    this.$store.dispatch('createNotification', { params: this.notification })
      .then(() => {
        this.$bvToast.toast('알림 발송이 정상적으로 완료되었습니다.', {
          title: '알림 발송',
          variant: 'success'
        })
        this.onReset()
      })
      .catch(({ response }) => {
        this.$bvToast.toast(response.data.message || response, {
          title: '알림 발송 오류',
          variant: 'danger'
        })
      })
      .finally(() => { this.preventSubmit = false })
  }

  onReset () {
    this.notification = JSON.parse(JSON.stringify(this.defaultNotification))
    this.$emit('reload')
  }
}
