
import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import { mapState } from 'vuex'

@Component({
  computed: { ...mapState(['isLoading']) },
})
export default class UserMemos extends Vue {
  @Prop() public user!: any

  isLoading!: boolean

  memos: any[] = []

  memo = ''
  withDeletedMemo = false

  changedColumn: string = ''

  mounted () {
    this.getUserMemos()
  }

  @Watch('withDeletedMemo')
  onWithDeletedMemoChanged () {
    this.getUserMemos()
  }

  getUserMemos () {
    let params

    if (this.withDeletedMemo) {
      params = { userId: this.user.id, params: { with_deleted: true } }
    } else {
      params = { userId: this.user.id }
    }

    this.$store.dispatch('getUserMemos', params)
      .then(({ data }) => this.memos = data.memos)
  }

  createUserMemo () {
    this.$store.dispatch('createUserMemo', { userId: this.user.id, params: { message: this.memo } })
      .then(() => this.getUserMemos())
      .finally(() => this.memo = '')
  }

  async deleteUserMemo (memo: any) {
    if (memo.deleted_at == null) {
      if (await this.$bvModal.msgBoxConfirm('해당 메모를 삭제하시겠습니까?', { okVariant: 'danger', cancelVariant: 'outline-secondary', centered: true })) {
        this.$store.dispatch('deleteUserMemo', { userId: this.user.id, id: memo.id })
          .then(() => this.getUserMemos())
      }
    } else {
      if (await this.$bvModal.msgBoxConfirm('해당 메모를 완전히 삭제하시겠습니까? (복구 불가)', { okVariant: 'dark', cancelVariant: 'outline-secondary', centered: true })) {
        this.$store.dispatch('removeUserMemo', { userId: this.user.id, id: memo.id })
          .then(() => this.getUserMemos())
      }
    }
  }

  get isMatchBlocked () {
    return this.user.match_blocked_at != null
  }

  set isMatchBlocked (value: boolean) {
    this.toggleUserData('match_blocked_at', value)
  }

  get isBlacklisted () {
    return this.user.blacklisted_at != null
  }

  set isBlacklisted (value: boolean) {
    this.toggleUserData('blacklisted_at', value)
  }

  get isAttentionMarked () {
    return this.user.attention_marked_at != null
  }

  set isAttentionMarked (value: boolean) {
    this.toggleUserData('attention_marked_at', value)
  }

  get isSelfiesAttentionMarked () {
    return this.user.selfies_attention_marked_at != null
  }

  set isSelfiesAttentionMarked (value: boolean) {
    this.toggleUserData('selfies_attention_marked_at', value)
  }

  get isTestModeEnabled () {
    return this.user.test_mode_enabled_at != null
  }

  set isTestModeEnabled (value: boolean) {
    this.toggleUserData('test_mode_enabled_at', value)
  }

  get isMatchEnabled () {
    return this.user.match_enabled_at != null
  }

  set isMatchEnabled (value: boolean) {
    this.toggleUserData('match_enabled_at', value)
  }

  toggleUserData (columnName: string, value: boolean) {
    this.changedColumn = columnName
    this.$set(this.user, this.changedColumn, value ? new Date() : null)
  }

  @Watch('isMatchEnabled')
  @Watch('isMatchBlocked')
  @Watch('isBlacklisted')
  @Watch('isAttentionMarked')
  @Watch('isSelfiesAttentionMarked')
  @Watch('isTestModeEnabled')
  updateUser () {
    let params: any = {}

    params[this.changedColumn] = this.user[this.changedColumn]

    this.$store.dispatch('updateUser', { id: this.user.id, params: params })
      .catch(({ response }) => {
        this.$bvToast.toast(response.data.message || response, {
          title: '정보 갱신 오류',
          variant: 'danger'
        })
      })
      .finally(() => this.$emit('reload'))
  }
}
