
import { Vue, Component } from 'vue-property-decorator'
import { mapState } from 'vuex'
import UserBadges from '@/components/UserBadges.vue'
import CoupleMessage from '@/components/CoupleMessage.vue'

@Component({
  components: {
    UserBadges,
    CoupleMessage
  },
  computed: { ...mapState(['isLoading']) },
})
export default class CoupleMessages extends Vue {
  coupleIds: any = []
  couples: any = []

  getCoupleMessages ($state: any) {
    this.$store.dispatch('getCoupleMessages', { params: { offset: this.couples.length }})
      .then(({ data }) => {
        if (data.couples.length > 0) {
          // 리스트에 있는 데이터 ID값 추출
          this.coupleIds = this.couples.map((couple: any) => couple.id)

          // 이미 리스트에 있는 데이터는 중복으로 노출되지 않도록 필터
          this.couples.push(...data.couples.filter((couple: any) => this.coupleIds.indexOf(couple.id) === -1))

          $state.loaded()
        } else {
          $state.complete()
        }
      })
  }
}
