
import { Vue, Component, Watch } from 'vue-property-decorator'
import InfiniteLoading from 'vue-infinite-loading'
import Profile from '@/components/Profile.vue'

@Component({
  components: { Profile },
  props: [ 'user' ],
})
export default class Profiles extends Vue {
  $refs!: {
    infiniteLoading: InfiniteLoading
  }

  user!: any
  profiles: any[] = []

  filterCategory: any = null

  @Watch('filterCategory')
  onVisibilityChanged () {
    this.profiles = []
    this.$refs.infiniteLoading.$emit('$InfiniteLoading:reset')
  }

  getProfiles ($state: any) {
    let params: any = {}

    if (this.filterCategory != null) {
      params['category'] = this.filterCategory
    }

    if (this.profiles.length > 0) {
      params['offset_id'] = this.profiles[this.profiles.length - 1].id
    }

    this.$store.dispatch('getUserProfiles', { userId: this.user.id, params: params })
      .then(({ data }) => {
        if (data.profiles.length > 0) {
          this.profiles.push(...data.profiles)

          $state.loaded()
        } else {
          $state.complete()
        }
      })
  }

  filterByCategory (category: any) {
    this.filterCategory = category
  }
}
