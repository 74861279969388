
import { Vue, Component } from 'vue-property-decorator'
import { mapState } from 'vuex'

@Component({
  props: [ 'user', 'size' ],
  computed: { ...mapState(['isLoading', 'currentUser']) },
})
export default class UserRedirectionButton extends Vue {
  redirectUser (id: number) {
    this.$store.dispatch('redirectUser', { id: id, params: {} }).then(() => this.$store.dispatch('getCurrentUser'))
  }
}
