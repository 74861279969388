
import { Vue, Component } from 'vue-property-decorator'
import UserBadges from '@/components/UserBadges.vue'

@Component({
  components: { UserBadges },
  props: [ 'user', 'church' ]
})
export default class ChurchUsers extends Vue {
  user!: any
  church!: any
  churchUsers: any[] = []

  get isUserComponent () { return this.user && this.user.id }

  getChurchUsers ($state: any) {
    let params: any = {}

    if (this.churchUsers.length > 0) {
      params['offset_id'] = this.churchUsers[this.churchUsers.length - 1].id
    }

    let payload: any = { params: params }

    if (this.isUserComponent) {
      payload['userId'] = this.user.id
    } else {
      payload['churchId'] = this.church.id
    }

    this.$store.dispatch('getChurchUsers', payload)
      .then(({ data }) => {
        if (data.users.length > 0) {
          this.churchUsers.push(...data.users)

          $state.loaded()
        } else {
          $state.complete()
        }
      })
  }
}
