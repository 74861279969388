
import { Vue, Component, Watch } from 'vue-property-decorator'
import InfiniteLoading from 'vue-infinite-loading'

@Component({ props: [ 'user' ] })
export default class Coins extends Vue {
  $refs!: {
    infiniteLoading: InfiniteLoading
  }

  user!: any
  coins: any[] = []

  status: string = 'all'

  get categories () {
    return [
      { text: '전체', value: 'all' },
      { text: '무료적립(미사용)', value: 'deposit_free' },
      { text: '무료적립(사용됨)', value: 'deposit_free_consumed' },
      { text: '유료적립', value: 'deposit_paid' },
      { text: '사용', value: 'withdraw' }
    ]
  }

  @Watch('status')
  resetCouples () {
    this.coins = []
    this.$refs.infiniteLoading.$emit('$InfiniteLoading:reset')
  }

  isActive (status: string): boolean { return this.status == status }

  getCoins ($state: any) {
    let params: any = { status: this.status }

    if (this.coins.length > 0) {
      params['offset_id'] = this.coins[this.coins.length - 1].id
    }

    this.$store.dispatch('getCoins', { userId: this.user.id, params: params })
      .then(({ data }) => {
        if (data.coins.length > 0) {
          this.coins.push(...data.coins)

          $state.loaded()
        } else {
          $state.complete()
        }
      })
  }
}
