
import { Vue, Component } from 'vue-property-decorator'
import { mapState } from 'vuex'

@Component({ computed: { ...mapState({ isLoading: "isLoading" }) } })
export default class Linkpay extends Vue {
  expiredAt?: Date = this.maxExpiredAt
  title: string = '' // 결제페이지 상단 제목
  name: string = '' // 결제페이지 구매 상품명
  amount: number = 100000 // 가격 (원)
  buyerTel: string = '' // 구매자 연락처

  paymentLinks: any[] = []

  mounted () {
    const paymentLinks = localStorage.getItem('paymentLinks')

    if (paymentLinks) this.paymentLinks = JSON.parse(paymentLinks)
  }

  get expiredAtEpoch () {
    return this.expiredAt ? Math.floor(this.expiredAt.getTime() / 1000) : undefined
  }

  get maxExpiredAt () {
    const maxDate = new Date(this.today)
    maxDate.setDate(maxDate.getDate() + 7)
    return maxDate
  }

  get minExpiredAt () {
    const minDate = new Date(this.today)
    minDate.setDate(minDate.getDate() + 1)
    return minDate
  }

  get today () {
    const now = new Date()
    return new Date(now.getFullYear(), now.getMonth(), now.getDate())
  }

  get paymentInfo () {
    return JSON.stringify({
      title: this.title,
      user_code: 'imp22072347',
      amount: this.amount,
      merchant_uid: 'CPM-' + this.$store.state.currentUser.id + '-' + Date.now(),
      buyer_tel: this.buyerTel,
      name: this.name,
      currency: 'KRW',
      pay_methods: [
        { pg: 'danal_tpay.6010037810', pay_method: 'card', label: '신용/체크카드' }
      ]
    })
  }

  onSubmit () {
    this.$store.dispatch('createPaylink', { params: { payment_info: this.paymentInfo, expired_at: this.expiredAtEpoch } })
      .then(({ data }) => this.setPaymentLink(data.shortenedUrl))
      .catch(({ response }) => {
        window.console.error(response)

        this.$bvToast.toast(response.data.message || response, {
          title: '결제 링크 생성 오류',
          variant: 'danger'
        })
      })
  }

  copyText(text: string) {
    this.$copyText(text)

    this.$bvToast.toast('결제 링크가 클립보드에 복사되었습니다.', {
      title: '결제 링크 복사',
      variant: 'primary'
    })
  }

  setPaymentLink (url: string) {
    window.console.log(url)

    this.copyText(url)

    this.paymentLinks.push({ url: url, created_at: Date.now(), buyer_tel: this.buyerTel })

    this.updatePaymentLinks()
  }

  updatePaymentLinks () {
    localStorage.setItem('paymentLinks', JSON.stringify(this.paymentLinks))
  }

  async removePaymentLink (index: number) {
    if (await this.$bvModal.msgBoxConfirm('해당 링크를 목록에서 지우시겠습니까?', { okVariant: 'dark', autoFocusButton: 'ok' })) {
      this.paymentLinks.splice(index, 1)

      this.updatePaymentLinks()
    }
  }
}
