
import { Vue, Component, Watch } from 'vue-property-decorator'
import { BDropdown } from 'bootstrap-vue'
import ProfileValue from '@/components/ProfileValue.vue'

@Component({
  components: { ProfileValue },
  props: ['profile', 'isReviewComponent', 'isLogComponent', 'plainHeader', 'preventEdit']
})
export default class Profile extends Vue {
  $refs!: {
    dropdown: BDropdown
  }

  profile!: any
  isReviewComponent!: boolean
  isLogComponent!: boolean
  preventEdit!: boolean

  isViewMode: boolean = true
  isValuesExpanded: boolean = true
  isFilterVisible: boolean = false

  editValue?: string

  get isReviewable (): boolean { return this.profile.is_reviewable && ! this.isLogComponent }
  get isCollapsable (): boolean { return this.$store.getters.isCollapsableProfile(this.profile.category) }
  get isArrayValue (): boolean { return Array.isArray(this.profile.value) }
  get isInterviewProfile (): boolean { return this.profile.category.includes('_interview') || this.profile.category == 'faith' }

  toggleFilter () {
    if (this.isLogComponent) this.$emit('filter-by-category', this.profile.category)
  }

  toggleViewMode () {
    this.isViewMode = ! this.isViewMode
    this.$refs.dropdown.hide(true)
  }

  @Watch('isViewMode')
  onViewModeChanged () {
    if (this.isViewMode) return

    if (this.preventEdit) {
      this.isViewMode = false
      if ( ! this.isLogComponent) {
        this.$bvToast.toast("수정이 불가능한 항목입니다.", {
          title: '알림',
          variant: 'danger'
        })
      }
    } else {
      this.editValue = this.valueToString
    }
  }

  hoverHandler (isHovered: any) {
    this.isFilterVisible = isHovered
  }

  get valueToString (): string {
    if (this.isArrayValue) {
      return JSON.stringify(this.profile.value, null, 4)
    } else {
      return this.profile.value
    }
  }

  /**
   * @returns {Option[]} 반려 유형 목록
   */
  get rejectionTemplates () {
    return this.$store.getters.getRejectionTemplates(this.profile.category)
  }

  setRejectionComment (rejectionTemplate: any) {
    this.$set(this.profile, 'rejection_comment', rejectionTemplate.value)
  }

  /**
   * @param {string} status 처리할 스테이터스
   * @description 프로필 심사처리, 페이지를 새로고침 할 경우도 있으므로 심사 처리는 상위 컴포넌트로 전달
   */
  reviewProfile (status: string) {
    this.$refs.dropdown.hide()
    this.$emit('review-profile', this.profile, status)
  }

  async updateProfile () {
    if (this.editValue && this.editValue != this.valueToString) {
      let params: {[category: string]: string} = {}

      try {
        if (this.isArrayValue) {
          params[this.profile.category] = JSON.parse(this.editValue)
        } else {
          params[this.profile.category] = this.editValue
        }
      } catch (error) {
        this.$bvToast.toast('입력값에 오류가 있습니다. JSON 형태를 유지해주세요', {
          title: '알림',
          variant: 'danger'
        })
        return
      }

      if (await this.$bvModal.msgBoxConfirm('저장하시겠습니까? (* 관리자 수정 시 바로 승인처리 됩니다.)', { okVariant: 'dark', autoFocusButton: 'ok' })) {
        this.$store.dispatch('updateProfile', { userId: this.profile.user_id, params: params })
          .then(() => {
            this.$emit('reload')
            this.toggleViewMode()
          })
          .catch(({ response }) => {
            this.$bvToast.toast(response.data.message || response, {
              title: '프로필 수정 오류',
              variant: 'danger'
            })
          })
      }
    } else {
      this.toggleViewMode()
    }
  }
}
