
import { Vue, Component, Watch } from 'vue-property-decorator'
import { mapState } from 'vuex'
import { BModal } from 'bootstrap-vue'
import CategoryBreadcrumb from '@/components/CategoryBreadcrumb.vue'
import UserCard from '@/components/UserCard.vue'

@Component({
  components: { CategoryBreadcrumb, UserCard },
  computed: { ...mapState(['isLoading']) }
})
export default class Consults extends Vue {
  $refs!: {
    consultLimits: BModal
  }

  users: any = []
  limits: any = [0, 0, 0, 0, 0, 0, 0]

  counts: any = {
    consults: 0,
  }

  mounted () {
    this.onCategoryChanged()
  }

  get categories () {
    return [
      { key: 'consults', text: '프로필 컨설팅', route: { name: 'consults', params: { category: 'consults' } } },
    ]
  }

  get category () { return (this.$route.params.category || this.categories[0].key).replace(/-/g, '_') }

  @Watch('category')
  onCategoryChanged () {
    this.users = []

    if (this.category) {
      this.getConsults()
    }
  }

  getConsults () {
    this.$store.dispatch('getConsults')
        .then(({ data }) => {
          this.users = data.users
          this.counts.consults = this.users.length

          this.limits = data.limits
        })
  }

  async completeConsult (userId: number) {
    if (await this.$bvModal.msgBoxConfirm(`[User#${userId}] 프로필 컨설팅을 완료 처리하시겠습니까?`, { okVariant: 'dark', autoFocusButton: 'ok' })) {
      this.$store.dispatch('updateConsult', { id: userId }).then(() => this.onCategoryChanged())
    }
  }

  updateConsultLimits () {
    this.$store.dispatch('updateConsultLimits', { params: { limits: this.limits } })
        .then(() => {
          this.onCategoryChanged()

          this.$bvToast.toast('프로필 컨설팅 신청량이 갱신되었습니다.', {
            title: '프로필 컨설팅',
            variant: 'success'
          })

          this.$refs.consultLimits.hide()
        })
  }
}
