
import { Vue, Component, Watch } from 'vue-property-decorator'
import { mapState } from 'vuex'
import UserBadges from '@/components/UserBadges.vue'
import CoupleMessage from '@/components/CoupleMessage.vue'
import ProfileValue from '@/components/ProfileValue.vue'
import Photos from '@/components/Photos.vue'
import Devices from '@/components/Devices.vue'
import UserMemos from '@/components/UserMemos.vue'
import NotificationForm from '@/components/NotificationForm.vue'

@Component({
  components: { UserBadges, CoupleMessage, ProfileValue, Photos, Devices, UserMemos, NotificationForm },
  computed: { ...mapState(['isLoading', 'currentUser']) },
})
export default class Couple extends Vue {
  couple: any = {}

  interviewFields = [
    'faith',
    'church_interviews',
    'place_interviews',
    'memory_interviews',
    'charm_interviews',
    'food_interviews',
    'prayer_interviews',
    'date_interviews',
    'school_interviews',
    'work_interviews',
    'vision_interview',
    'episode_interviews',
    'hope_interviews'
  ]

  created () {
    this.getCouple()
  }

  get coupleId () { return this.$route.params.coupleId }

  @Watch('coupleId')
  onCoupleIdChanged () {
    this.couple = {}
    this.getCouple()
  }

  getCouple () {
    this.$store.dispatch('getCouple', { id: this.coupleId })
      .then(({ data }) => { this.couple = data.couple })
  }

}
