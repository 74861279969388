
import { Vue, Component } from 'vue-property-decorator'

@Component({ props: [ 'user' ] })
export default class ActivityLogs extends Vue {
  user!: any
  activityLogs: any[] = []

  get booleanTimestampColumns () {
    return [
      "push_enabled_at",
      "match_enabled_at",
      "match_blocked_at",
      "blacklisted_at",
      "attention_marked_at",
      "selfies_attention_marked_at",
      "test_mode_enabled_at"
    ]
  }

  getActivityLogs ($state: any) {
    let params: any = {}

    if (this.activityLogs.length > 0) {
      params['offset_id'] = this.activityLogs[this.activityLogs.length - 1].id
    }

    this.$store.dispatch('getActivityLogs', { userId: this.user.id, params: params })
      .then(({ data }) => {
        if (data.activity_logs.length > 0) {
          this.activityLogs.push(...data.activity_logs)

          $state.loaded()
        } else {
          $state.complete()
        }
      })
  }
}
