import Vue from 'vue'
import App from '@/App.vue'
import '@/registerServiceWorker'
import router from '@/router'
import store from '@/store'
import firebase from 'firebase/app'
import 'firebase/auth'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import moment from 'moment'
import * as VueMoment from 'vue-moment'
import 'moment/locale/ko'
import * as filters from '@/filters'
import InfiniteLoading from 'vue-infinite-loading'
import TextHighlight from 'vue-text-highlight'
import VueClipboard from 'vue-clipboard2'

Vue.config.productionTip = false

Vue.use(BootstrapVue, {
  BToast: {
    toaster: 'b-toaster-bottom-right',
    autoHideDelay: 1500
  }
})
Vue.use(IconsPlugin)
Vue.use(VueMoment, { moment }) // Initialize with Locale
Vue.use(InfiniteLoading)
Vue.use(VueClipboard)

Object.entries(filters).forEach(([key, filter]) => {
  Vue.filter(key, filter)
})

Vue.component('text-highlight', TextHighlight)

// Initialize Firebase
firebase.initializeApp({
  apiKey: "AIzaSyAA9u8xOEEbNTDO95RaRZmjUAtxS6xx_lk",
  authDomain: "auth.fb.christiandate.kr",
  projectId: "christiandate-7a511",
  storageBucket: "christiandate-7a511.appspot.com",
  messagingSenderId: "380802193500",
  appId: "1:380802193500:web:5e13d3d9c07d613f7cae99",
  measurementId: "G-328QFP7T36"
})

firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)

router.beforeEach((to, from, next) => {
  store.dispatch('updateIPinfo')

  if (firebase.auth().currentUser) {
    if (to.meta.loginPage) {
      next({ path: '/' })
    } else {
      next()
    }
  } else {
    if (to.meta.loginPage) {
      next()
    } else {
      next({ path: '/login' })
    }
  }
})

// Initialize Vue
let vue: Vue

firebase.auth().onAuthStateChanged((user) => {
  if ( ! vue) {
    store.dispatch('updateIPinfo')

    if (user) store.dispatch('getCurrentUser')

    vue = new Vue({
      router,
      store,
      render: h => h(App)
    }).$mount('#app')
  }
})
