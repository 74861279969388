
import { Vue, Component, Watch } from 'vue-property-decorator'

@Component({ props: ['user'] })
export default class NotificationForm extends Vue {
  user!: any

  preventSubmit: boolean = false

  product: any = this.defaultProduct

  get defaultProduct () {
    let product = {
      user_ids: '',
      amount: NaN,
      paid_amount: NaN,
      free_amount: NaN,
      description: '',
      comment: '',
    }

    if (this.user) product.user_ids = "," + this.user.id

    return product
  }

  @Watch('product.paid_amount')
  @Watch('product.free_amount')
  onChangedAmount () {
    if ( ! isNaN(this.product.free_amount) && ! isNaN(this.product.paid_amount)) {
      this.product.amount = parseInt(this.product.free_amount) + parseInt(this.product.paid_amount)
    }
  }

  onSubmit () {
    this.preventSubmit = true

    this.$store.dispatch('createCoin', { params: this.product })
      .then(() => {
        this.$bvToast.toast('달란트 내역 추가가 정상적으로 완료되었습니다.', {
          title: '달란트 내역 추가',
          variant: 'success'
        })
        this.onReset()
      })
      .catch(({ response }) => {
        this.$bvToast.toast(response.data.message || response, {
          title: '달란트 내역 추가 오류',
          variant: 'danger'
        })
      })
      .finally(() => { this.preventSubmit = false })
  }

  onReset () {
    this.product = this.defaultProduct
    this.$emit('reload')
  }
}
