
import { Vue, Component } from 'vue-property-decorator'
import { mapState } from 'vuex'

@Component({
  props: [ 'user' ],
  computed: { ...mapState(['isLoading']) },
})
export default class Statistics extends Vue {
  user!: any
  statistic: any = {}

  mounted () {
    this.getStatistics()
  }

  get coupleStatistics () { return this.statistic.couple_statistics }
  get coinStatistics () { return this.statistic.coin_statistics }

  getStatistics () {
    this.$store.dispatch('getUserStatistics', { userId: this.user.id })
      .then(({ data }) => this.statistic = data.statistic)
  }

  updateUserStatistics () {
    this.$store.dispatch('updateUserStatistics', { userId: this.user.id })
      .then(() => this.getStatistics)
  }
}
