
import { Vue, Component } from 'vue-property-decorator'
import Certificate from '@/components/Certificate.vue'
import InfiniteLoading from 'vue-infinite-loading'

@Component({
  components: { Certificate },
  props: [ 'user' ],
})
export default class Certificates extends Vue {
  $refs!: {
    infiniteLoading: InfiniteLoading
  }

  user!: any
  certificates: any[] = []

  resetCertificates () {
    this.certificates = []
    this.$refs.infiniteLoading.$emit('$InfiniteLoading:reset')
  }

  getCertificates ($state: any) {
    let params: any = {}

    if (this.certificates.length > 0) {
      params['offset_id'] = this.certificates[this.certificates.length - 1].id
    }

    this.$store.dispatch('getUserCertificates', { userId: this.user.id, params: params })
      .then(({ data }) => {
        if (data.certificates.length > 0) {
          this.certificates.push(...data.certificates)

          $state.loaded()
        } else {
          $state.complete()
        }
      })
  }
}
